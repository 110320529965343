h1.title {
  text-align: center;
  margin-bottom: 8px;
  color: var(--vkui--color_text_primary);
}

h2.subhead {
  text-align: center;
  margin-bottom: 12px;
  color: var(--vkui--color_text_primary);
}
